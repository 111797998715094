import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FaqLayout from "../../components/faq-layout"
import locale from "../../locale/en"
import HandSanitizerContent from "../../components/faq-content/hand-sanitizer"

const { faq } = locale

const HandSanitizer = () => (
  <Layout>
    <SEO title={faq.hand_sanitizer} />
    <FaqLayout title={faq.hand_sanitizer}>
      <HandSanitizerContent />
    </FaqLayout>
  </Layout>
)

export default HandSanitizer
